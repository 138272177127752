.awsui_root_1xupv_1e7e8_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:var(--font-body-m-size-3mVn35, 14px);
  line-height:22px;
  line-height:var(--font-body-m-line-height-3H4gFo, 22px);
  color:#16191f;
  color:var(--color-text-body-default-2BCA55, #16191f);
  font-weight:400;
  font-family:"Noto Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-family-base-2X3QfY, "Noto Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
}

.awsui_wizard_1xupv_1e7e8_13:not(#\9){
  display:flex;
}

.awsui_form_1xupv_1e7e8_17:not(#\9){
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
  width:100%;
}

.awsui_navigation_1xupv_1e7e8_33:not(#\9){
  color:#aab7b8;
  color:var(--color-text-disabled-11iwk5, #aab7b8);
  display:inline-block;
  width:200px;
  min-width:200px;
  margin-right:calc(2 * 40px);
  margin-right:calc(2 * var(--space-xxxl-2qIOmN, 40px));
  padding-top:4px;
  padding-top:var(--space-xxs-32HdAH, 4px);
}

.awsui_hidden_1xupv_1e7e8_42:not(#\9){
  display:none;
}

.awsui_navigation-links_1xupv_1e7e8_46:not(#\9){
  list-style:none;
  padding-left:0;
  margin:0;
}
.awsui_navigation-links_1xupv_1e7e8_46 > li:not(#\9){
  padding-bottom:16px;
  padding-bottom:var(--space-scaled-m-3f-qBw, 16px);
  padding-top:0;
}
.awsui_navigation-links_1xupv_1e7e8_46 > li:not(#\9):not(:first-child){
  margin-top:16px;
  margin-top:var(--space-scaled-m-3f-qBw, 16px);
}
.awsui_navigation-links_1xupv_1e7e8_46 > li:not(#\9):not(:last-child){
  border-bottom:1px solid #d5dbdb;
  border-bottom:var(--border-divider-list-width-3SLtCq, 1px) solid var(--color-border-layout-w74Klp, #d5dbdb);
}

.awsui_navigation-link-active_1xupv_1e7e8_68:not(#\9){
  font-weight:700;
  color:#16191f;
  color:var(--color-text-body-default-2BCA55, #16191f);
}

.awsui_navigation-link-disabled_1xupv_1e7e8_73:not(#\9){
  color:#aab7b8;
  color:var(--color-text-control-disabled-2RRYfX, #aab7b8);
}